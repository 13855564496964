.swiper-button-prev,
.swiper-button-next {
  color: white;
}

.filterBox {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2rem 2rem 3rem 2rem;
}

.searchtype-dropdown {
  margin-right: 1rem;
}

.searchtype-dropdown button {
  background-color: #349949;
  border: none;
  height: 55px;
  width: 7rem;
  white-space: nowrap;
  overflow: hidden; 
  text-overflow: ellipsis; 
}

.searchtype-dropdown button:focus,
.searchtype-dropdown button:hover {
background: #1e7e34 !important; 
border: none !important;
box-shadow: none !important;
}

.searchtype-dropdown div {
  background: #d9d9d9e8;
  min-width: 7rem;
}

.searchtype-dropdown div a {
height: 2rem;

}