.auto-scroll-banner {
  white-space: nowrap;
  overflow: hidden;
  position: relative;
  background-color: rgb(255 182 76); 
  display: flex;
  height: 2rem;
  align-items: center;
}

.content {
  width: 100%;
  color: #144667;
  white-space: nowrap; 
  animation: scroll 25s linear infinite; 
  font-weight: 500;
}

@keyframes scroll {
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(-100%);
  }
}

/* Media query for screens less than 950px in width */
@media (max-width: 950px) {
  .content {
    width: 1000px;
    animation: scroll 18s linear infinite;
  }
}