.jamaath-masjid-list-item {
  background: white;
  width: 100%;
  height: 10rem;
  border-radius: 14px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  padding: 1rem;
  display: flex;
  margin-bottom: 1rem;
}

.jamaath-masjid-list-item-img {
  height: 8rem;
  border-radius: 1rem;
}

.jamaath-masjid-list-item-details-container {
  margin-left: 1rem;
}

.jamaath-masjid-list-item-button {
  background: #edcd23;
  padding: 7px;
  border-radius: 10px;
  font-size: small;
  font-weight: 800;
  color: #2c723f;
}

.jamaath-masjid-list-item-detail {
  text-overflow: ellipsis;
  overflow: hidden;
  width: 11%;
  height: 4.2em;
  white-space: nowrap;
}
