.seachtype-dropdown {
  margin-right: 1rem;
}

.seachtype-dropdown button {
  background-color: #e7e3de5e;
  border: none;
  height: 55px;
  width: 7rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.seachtype-dropdown button:focus,
.seachtype-dropdown button:hover {
  background: #e7e3de5e !important;
  border: none !important;
  box-shadow: none !important;
}

.seachtype-dropdown div {
  background: #d9d9d9e8;
  min-width: 7rem;
}

.seachtype-dropdown div a {
  height: 2rem;
}

.home-flip-img {
  transition: transform 0.3s ease-in-out;
}

.home-flip-img:hover {
  transform: scaleX(-1);
}

.dp-container {
  display: flex;
  overflow-x: auto; /* Enable horizontal scroll */
  white-space: nowrap; /* Prevent cards from wrapping to the next line */
  padding-top: 20px;
  padding-bottom: 20px;
}

.dp-card {
  flex: 0 0 auto; /* Prevent cards from growing to fill the container */
  width: 300px; /* Adjust the card width as needed */
  margin-right: 20px; /* Adjust the space between cards as needed */
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); /* Add a subtle box shadow for styling */
  text-align: center;
}

.dp-card img {
  width: 100%;
  height: 200px; /* Adjust the image height as needed */
  object-fit: cover; /* Maintain aspect ratio while covering the entire container */
}

.dp-card h3,
.dp-card p {
  margin: 10px 0;
}

.dp-section {
    margin-top: 3rem;
    margin-bottom: 3rem;
}