.custom-dropdown-button button {
    background-color: #e7e3de5e;
    border: none;
  }

.custom-dropdown-button button:focus,
.custom-dropdown-button button:hover {
  background: #e7e3de5e !important; 
  border: none !important;
  box-shadow: none !important;
}

.custom-dropdown-button div {
    background: #d9d9d9e8;
    min-width: 2rem;
}

.custom-dropdown-button div a {
height: 2rem;

}